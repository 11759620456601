import * as React from 'react';
import styled from 'styled-components';
import Section from '../components/Section';
import Wrapper from '../components/Wrapper';
// const FontFaceObserver = require('fontfaceobserver');

const Main = styled.main`
  background: #fff;
  padding: 150px 4rem;
  color: #101119;
  line-height: 1.75;

  h1 {
    font-family: 'Inter';
    text-shadow: none;
    color: inherit;
    text-align: center;
    margin: 0 auto;
    max-width: 700px;
    line-height: 1;
  }

  h3 {
    font-weight: 500;
    max-width: 700px;
    font-size: 20px;
    text-align: center;
    margin: 1rem auto;
  }

  p {
    font-size: 16px;
    max-width: 700px;
    line-height: 1.75;
    margin: 3rem 0;
  }

  @media (max-width: 500px) {
    padding: 100px 2rem;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

const ColSmall = styled.div`
  width: 33.33%;

  @media (max-width: 900px) {
    width: 100%;
  }
`;

const ColLarge = styled.div`
  width: 66.66%;

  @media (max-width: 900px) {
    width: 100%;
  }
`;

const GridItem = styled.div`
  background: #efefef;
  border: 1px solid #ddd;
  height: 220px;
  margin: 1rem;
  padding: 3rem 2rem;
  line-height: 1.5;

  h3 {
    text-align: left;
    font-weight: bold;
  }

  p {
    margin: 0;
    font-size: 14px;
  }

  @media (max-width: 900px) {
    height: auto;
  }
`;

const PrivacyPage = () => {
  // const [fontLoaded, setFontLoaded] = React.useState(false);
  // const font = new FontFaceObserver('Inter');

  // React.useEffect(() => {
  // 	font.load().then(function () {
  // 		setFontLoaded(true);
  // 	});
  // }, [setFontLoaded, font]);

  // if (!fontLoaded) return null;

  return (
    <Main>
      <Section>
        <Wrapper>
          <h1>Schedule a discovery call with our team.</h1>

          <h3>
            As a full service athlete representation and management business,
            our priority is to help our clients reach their full potential both
            during and after their professional careers.
          </h3>

          <br />

          <Row>
            <ColSmall>
              <GridItem>
                <h3>Contract Negotiations</h3>
                <p>
                  Negotiating market setting contracts and maximizing the value
                  of each of our clients.
                </p>
              </GridItem>
            </ColSmall>

            <ColSmall>
              <GridItem>
                <h3>Pre-Draft Preparation</h3>
                <p>
                  Investing in clients from the outset through a comprehensive
                  pre-draft process with elite skills trainers, strength and
                  conditioning professionals, and nutrition and recovery
                  specialists.
                </p>
              </GridItem>
            </ColSmall>

            <ColSmall>
              <GridItem>
                <h3>Public Relations and Social Media</h3>
                <p>
                  Leveraging long standing media relations and using creative
                  social media practices to maximize fan engagement and
                  outreach.
                </p>
              </GridItem>
            </ColSmall>
          </Row>

          <Row>
            <ColSmall>
              <GridItem>
                <h3>Marketing</h3>
                <p>
                  Securing endorsement opportunities and brand activations and
                  partnerships including, but not limited to, equity stakes.
                </p>
              </GridItem>
            </ColSmall>

            <ColLarge>
              <GridItem>
                <h3>NIL Representation</h3>
                <p>
                  The opportunity for student-athletes to capitalize on their
                  Name, Image, and Likeness is unprecedented. You've earned your
                  spot in history and now it is time to monetarily capitalize on
                  your brand.
                </p>
              </GridItem>
            </ColLarge>
          </Row>

          <Row>
            <ColLarge>
              <GridItem>
                <h3>Post-Career Planning</h3>
                <p>
                  Tackling post-career planning early so our clients are
                  protected as they transition to a life outside of playing
                  professional sports.
                </p>
              </GridItem>
            </ColLarge>

            <ColSmall>
              <GridItem>
                <h3>Community Outreach</h3>
                <p>
                  Assisting clients create a legacy by being involved with
                  community organizations and in community initiatives.
                </p>
              </GridItem>
            </ColSmall>
          </Row>
        </Wrapper>
      </Section>
    </Main>
  );
};

export default PrivacyPage;
